import request from '@/utils/request'
/**
 * 查询采集列表
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    reviewResults (integer, optional): 审核结果: 1通过 2拒绝 ,
    advertisersName (string, optional): 广告主名称 ,
    collectionUserName (string, optional): 采集人姓名 ,
    industryCode (string, optional): 行业编号 ,
    maxAddTime (string, optional): 最大添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    minAddTime (string, optional): 最小添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    province (string, optional): 省 ,
    city (string, optional): 市 ,
    district (string, optional): 区 ,
 } 
 */
export function getCollectionPageList (data) {
    return request({
        url: '/datacollection/queryPageList',
        method: 'post',
        data
    })
}

/**
 * 查询媒介列表（分页）
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    type (integer, optional): 类型：1 媒介类型枚举值；2 任务审核拒绝枚举值 ,
 } 
 */
export function getMediaTypePageList (data) {
    return request({
        url: '/systemproperties/queryPageList',
        method: 'post',
        data
    })
}

/**
 * 查询媒介列表（不分页）
 * @param  data 
 * @returns 
 * data:{
    type (integer, optional): 类型：1 媒介类型枚举值；2 任务审核拒绝枚举值 ,
 } 
 */
export function getMediaTypeList (data) {
    return request({
        url: '/systemproperties/queryList',
        method: 'post',
        data
    })
}

/**
 * 查询点位列表
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    name (string, optional): 点位名称 ,
    maxAddTime (string, optional): 最大添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    minAddTime (string, optional): 最小添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    province (string, optional): 省 ,
    city (string, optional): 市 ,
    district (string, optional): 区 ,
 } 
 */
export function getDatapointPageList (data) {
    return request({
        url: '/datapoint/queryPageList',
        method: 'post',
        data
    })
}

/**
 * 查询点位列表（不分页）
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    name (string, optional): 点位名称 ,
    maxAddTime (string, optional): 最大添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    minAddTime (string, optional): 最小添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    province (string, optional): 省 ,
    city (string, optional): 市 ,
    district (string, optional): 区 ,
 } 
 */
export function getDatapointList (data) {
    return request({
        url: '/datapoint/queryList',
        method: 'post',
        data
    })
}

/**
 * 新增系统枚举值(媒介管理、审核拒绝枚举值)
 * @param  data 
 * @returns 
 * data:{
    extend (string, optional): 配置值的扩展属性 ,
    type (integer, optional): 类型：1 媒介类型枚举值；2 任务审核拒绝枚举值 ,
    userCode (string, optional): 用户编号 ,
    value (string, optional): 配置值
 } 
 */
export function systempropertiesAdd (data) {
    return request({
        url: '/systemproperties/add',
        method: 'post',
        data
    })
}

/**
 * 删除系统枚举值(媒介管理、审核拒绝枚举值)
 * @param  data 
 * @returns 
 * data:{
    flowId (integer): 流水号 ,
 } 
 */
export function systempropertiesDelete (data) {
    return request({
        url: '/systemproperties/delete',
        method: 'get',
        params: data
    })
}

/**
 * 更新系统枚举值(媒介管理、审核拒绝枚举值)
 * @param  data 
 * @returns 
 * data:{
    flowId (integer): 流水号 ,
    extend (string, optional): 配置值的扩展属性 ,
    type (integer, optional): 类型：1 媒介类型枚举值；2 任务审核拒绝枚举值 ,
    userCode (string, optional): 用户编号 ,
    value (string, optional): 配置值
 } 
 */
export function systempropertiesUpdate (data) {
    return request({
        url: '/systemproperties/update',
        method: 'post',
        data
    })
}

/**
 * 更新系统枚举值(媒介管理、审核拒绝枚举值)
 * @param  data 
 * @returns 
 * data:{
    flowId (integer): 流水号 ,
 } 
 */
export function systempropertiesQuery (data) {
    return request({
        url: '/systemproperties/query',
        method: 'get',
        params: data
    })
}

/**
 * 区代账号设置任务领取限制
 * @param  data 
 * @returns 
 * data:{
    taskReceiveLimitInfo (TaskReceiveLimitInfo, optional): 任务领取限制对象 ,
    type (integer, optional): 类型：1 媒介类型枚举值；2 任务审核拒绝枚举值 ,
 } 
    TaskReceiveLimitInfo {
        taskCount (integer, optional),
        picCount (integer, optional)
    }
 */
export function taskReceiveAuthority (data) {
    return request({
        url: '/systemproperties/taskReceiveAuthority',
        method: 'post',
        data
    })
}

/**
 * 查询区代账号任务权限
 * @param  data 
 * @returns 
 * data:{
    type (integer, optional): 类型：1 媒介类型枚举值；2 任务审核拒绝枚举值 ,
    userCode (string, optional): 用户编号 ,
 } 
 */
export function getAgentAcountTaskAuth (data) {
    return request({
        url: '/systemproperties/queryList',
        method: 'post',
        data
    })
}

/**
 * 媒介名称唯一校验
 * @param  data 
 * @returns 
 * data:{
    type (integer, optional): 类型：1 媒介类型枚举值；2 任务审核拒绝枚举值
    value (string, optional): 配置值 ,
 } 
 */
export function uniqueValidatorMediaName (data) {
    return request({
        url: '/systemproperties/uniqueValidator',
        method: 'post',
        data
    })
}

/**
 * 新增点位
 * @param  data 
 * @returns 
 * data:{
    adCode (string, optional): 区号 ,
    address (string, optional): 地址 ,
    city (string, optional): 市 ,
    district (string, optional): 区/县 ,
    latitude (string, optional): 纬度 ,
    longitude (string, optional): 经度 ,
    name (string, optional): 点位名称 ,
    province (string, optional): 省份 ,
    url (string, optional): 图片地址 多个地址前端自己分段 ,
    userCode (string, optional): 用户编号
 } 
 */
export function dataPointAdd (data) {
    return request({
        url: '/datapoint/add',
        method: 'post',
        data
    })
}

/**
 * 更新点位
 * @param  data 
 * @returns 
 * data:{
    pointId (string): 点位编号 ,
    adCode (string, optional): 区号 ,
    address (string, optional): 地址 ,
    city (string, optional): 市 ,
    district (string, optional): 区/县 ,
    latitude (string, optional): 纬度 ,
    longitude (string, optional): 经度 ,
    name (string, optional): 点位名称 ,
    province (string, optional): 省份 ,
    url (string, optional): 图片地址 多个地址前端自己分段 ,
    userCode (string, optional): 用户编号
 } 
 */
export function dataPointUpdate (data) {
    return request({
        url: '/datapoint/update',
        method: 'post',
        data
    })
}

/**
 * 点位详情查询
 * @param  data 
 * @returns 
 * data:{
    pointId (string)
 } 
 */
export function dataPointQuery (data) {
    return request({
        url: '/datapoint/query',
        method: 'get',
        params: data
    })
}

/**
 * 删除点位
 * @param  data 
 * @returns 
 * data:{
    pointId (string)
 } 
 */
export function dataPointDel (data) {
    return request({
        url: '/datapoint/delete',
        method: 'get',
        params: data
    })
}