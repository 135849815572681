<template>
    <div class="app-container">
        <el-row>
            <el-col :span="12">
                <el-row class="titleRow">
                    <span class="longString"></span>
                    任务权限
                </el-row>
                <el-row class="marginBottom-8">
                    一人一天可领任务数：
                    <el-input style="width: 30%" v-model="taskCount" oninput="value=value.replace(/[^0-9]/g,'').replace(/^0{1,}/g,'')" autocomplete="off" type="number" clearable placeholder="请输入任务个数">
                        <template slot="append">个</template>
                    </el-input>
                    <el-switch class="marginLeft-8" active-text="开" inactive-text="关" v-model="taskSwitch"></el-switch>
                </el-row>
                <el-row>
                    一人一天可领图片数：
                    <el-input style="width: 30%" v-model="picCount" oninput="value=value.replace(/[^0-9]/g,'').replace(/^0{1,}/g,'')" autocomplete="off" type="number" clearable placeholder="请输入图片数量">
                        <template slot="append">张</template>
                    </el-input>
                    <el-switch class="marginLeft-8" active-text="开" inactive-text="关" v-model="imgSwitch"></el-switch>
                </el-row>
                <el-row class="taskAuth marginTop-8">
                    <span class="marginRight-16">设置完后，请点击提交按钮，否则设置不生效</span>
                    <el-button size="mini" type="primary" @click="taskAuthSubmit">提交</el-button>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { taskReceiveAuthority, getAgentAcountTaskAuth } from "@/api/statisticalReport";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            taskCount: "",
            picCount: "",
            taskSwitch: false,
            imgSwitch: false,
            flowId: null,
        };
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    created() {
        this.getAgentAcountTaskAuth();
    },
    methods: {
        taskAuthSubmit() {
            if (this.taskSwitch && (this.taskCount == "" || this.taskCount == 0)) {
                this.$message({
                    message: "请输入任务个数，不能为空或0",
                    type: "warning",
                });
                return;
            }
            if (this.imgSwitch && (this.picCount == "" || this.picCount == 0)) {
                this.$message({
                    message: "请输入图片张数，不能为空或0",
                    type: "warning",
                });
                return;
            }
            let params = {
                type: 2,
                userCode: this.userInfo.userCode,
                taskReceiveLimitInfo: {
                    taskCount: this.taskSwitch ? this.taskCount : "",
                    picCount: this.imgSwitch ? this.picCount : "",
                },
            };
            if (this.flowId) {
                params.flowId = this.flowId;
            }
            const _this = this;
            taskReceiveAuthority(params)
                .then((res) => {
                    _this.$message({
                        message: "设置成功",
                        type: "success",
                    });
                    _this.getAgentAcountTaskAuth();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getAgentAcountTaskAuth() {
            let params = {
                type: 2,
                userCode: this.userInfo.userCode,
            };
            const _this = this;
            getAgentAcountTaskAuth(params)
                .then((res) => {
                    if (res.data.dataList.length != 0) {
                        _this.flowId = res.data.dataList[0].flowId;
                        let extend = res.data.dataList[0].extend;
                        if (extend) {
                            let data = JSON.parse(res.data.dataList[0].extend);
                            _this.taskCount = data.taskCount || "";
                            _this.picCount = data.picCount || "";
                            if (data.taskCount) {
                                _this.taskSwitch = true;
                            }
                            if (data.picCount) {
                                _this.imgSwitch = true;
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.taskAuth {
    span {
        color: #f03434;
    }
    padding-left: 130px;
}
</style>
